<template>
    <div class="search-bar" :class="{ 'mobile-visible': searchOpened }">
        <div
            id="header-search-suggestions"
            v-if="showSuggestions && queryText.length > 0"
            :class="{ 'with-search-results': suggestions.items.length }"
        >
            <div class="suggestions" :class="{ active: searchInFocus }">
                <div class="results">
                    <a
                        v-for="(searchSuggestion, index) in suggestions.items.slice(0, suggestions.limit)"
                        :key="index"
                        href="#"
                        @click.prevent="chooseSearchSuggestion(searchSuggestion)"
                        class="suggestion-item"
                        :class="{ active: suggestions.active === index }"
                    >
                        <span>{{ searchSuggestion }}</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="form-element">
            <div class="smart-search-input-wrapper" :class="{ active: searchInFocus }">
                <input
                    id="search"
                    type="search"
                    ref="searchInputRef"
                    :placeholder="t.header.searchPlaceholder"
                    @input="onchangeSearchQuery"
                    @focus="focusSearch"
                    @blur="blurSearch"
                    @keyup="keyboardKeyUp"
                    class="suggest"
                    maxlength="255"
                    v-model="queryText"
                    autocomplete="off"
                />
                <button v-if="queryText.length > 0" class="clean-search-field" @click="cleanQuery">✕</button>
                <button id="header-search-button" @click="toSearchPage" title="Jetzt suchen" aria-label="Search">
                    <svg class="icon sprite-icon sprite-icon-search">
                        <use xlink:href="/sprites/ui.svg#search" fill="white"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue"
import { useTutkitStore } from "@/frontend/tutkit-store.js"
const globalJs = window.globalJs
export default {
    name: "SmartSearch",
    setup() {
        const tutkitStore = useTutkitStore()
        const queryText = ref("")
        const searchInFocus = ref(false)
        const searchInputRef = ref(null)
        const searchOpened = ref(false)
        const t = globalJs.translations

        const suggestions = computed(() => tutkitStore.search.suggestions)
        const showSuggestions = computed(() => suggestions.value.show && queryText.value.length > 0)

        let typingSuggestionsTimeout = null
        let typingResultsTimeout = null

        if (!tutkitStore.search.meiliClient) tutkitStore.setupMeiliSearch()

        function onchangeSearchQuery(event) {
            clearTimeout(typingSuggestionsTimeout)
            clearTimeout(typingResultsTimeout)
            const newValue = event.target.value
            queryText.value = newValue

            typingSuggestionsTimeout = setTimeout(() => {
                tutkitStore.getSearchSuggestions(newValue)
            }, 100)

            if (window.location.pathname === tutkitStore.search.routes.searchPage) {
                typingResultsTimeout = setTimeout(() => {
                    tutkitStore.getSearchResult(newValue)
                }, 200)
            }
        }

        function chooseSearchSuggestion(searchSuggestion) {
            queryText.value = searchSuggestion
            tutkitStore.updatePageLoadQuery(searchSuggestion)
            tutkitStore.getSearchResult(searchSuggestion)
            tutkitStore.getSearchSuggestions(searchSuggestion)
            window.sessionStorage.setItem("searchQuery", searchSuggestion)
            showSearchSuggestions(false)
            toSearchPage()
        }

        function showSearchSuggestions(boolShow) {
            tutkitStore.showSearchSuggestions(boolShow)
        }

        function focusSearch() {
            searchInFocus.value = true
            showSearchSuggestions(true)
        }

        function blurSearch() {
            searchInFocus.value = false
        }

        function cleanQuery() {
            queryText.value = ""
            tutkitStore.getSearchResult("")
        }

        function toSearchPage() {
            if ((window.visualViewport.width <= 740 && searchOpened.value) || window.visualViewport.width > 740) {
                if (window.location.pathname !== tutkitStore.search.routes.searchPage) {
                    window.sessionStorage.setItem("searchQuery", queryText.value)
                    const searchPageURL = window.location.origin + tutkitStore.search.routes.searchPage
                    window.location.href = searchPageURL
                }
            }
            searchOpened.value = !searchOpened.value
        }

        function keyboardKeyUp(event) {
            // NEED TO REFACTOR THIS METHOD
            let currentActiveSuggestion = tutkitStore.search.suggestions.active
            let currentActiveSuggestionText = tutkitStore.search.suggestions.items[currentActiveSuggestion]
            let suggestionsLength = tutkitStore.search.suggestions.limit - 1
            if (event.keyCode === 13) {
                if (tutkitStore.search.suggestions.active !== false) {
                    tutkitStore.search.query =
                        tutkitStore.search.suggestions.items[tutkitStore.search.suggestions.active]
                    window.sessionStorage.setItem(
                        "searchQuery",
                        tutkitStore.search.suggestions.items[tutkitStore.search.suggestions.active],
                    )
                    queryText.value = tutkitStore.search.suggestions.items[tutkitStore.search.suggestions.active]
                    tutkitStore.search.suggestions.show = false
                    tutkitStore.search.suggestions.active = false
                    chooseSearchSuggestion(queryText.value)
                }
                if (window.location.pathname !== tutkitStore.search.routes.searchPage && currentActiveSuggestion) {
                    window.sessionStorage.setItem("searchQuery", currentActiveSuggestionText)
                }
                this.queryText = queryText.value
                tutkitStore.updateSearchQuery(queryText.value)
                showSearchSuggestions(false)
                toSearchPage()
                tutkitStore.changeActiveSuggestion(false)
            } else if (event.keyCode === 40) {
                // Arrow down
                if (currentActiveSuggestion !== false && currentActiveSuggestion < suggestionsLength) {
                    currentActiveSuggestion++
                } else {
                    currentActiveSuggestion = 0
                }
                tutkitStore.changeActiveSuggestion(currentActiveSuggestion)
            } else if (event.keyCode === 38) {
                // Arrow up
                if (currentActiveSuggestion !== false && currentActiveSuggestion > 0) {
                    currentActiveSuggestion--
                } else {
                    currentActiveSuggestion = suggestionsLength
                }
                tutkitStore.changeActiveSuggestion(currentActiveSuggestion)
            } else {
                showSearchSuggestions(true)
                tutkitStore.changeActiveSuggestion(false)
            }
        }

        onMounted(() => {
            if (window.location.pathname !== tutkitStore.search.routes.searchPage) {
                tutkitStore.search.query = ""
                window.sessionStorage.removeItem("searchQuery")
            }
            const initSearchQuery = window.sessionStorage.getItem("searchQuery") || ""
            if (initSearchQuery) {
                queryText.value = initSearchQuery
                tutkitStore.updatePageLoadQuery(initSearchQuery)
                tutkitStore.getSearchResult(initSearchQuery)
            }
            document.getElementById("close-search-field").addEventListener("click", () => {
                searchOpened.value = !searchOpened.value
            })
        })

        onBeforeUnmount(() => {
            window.removeEventListener("click", outsideClickHandler)
        })

        function outsideClickHandler(event) {
            if (event.target.id !== "search") {
                showSearchSuggestions(false)
            }
        }

        watch(searchOpened, (newValue, oldValue) => {
            if (newValue) {
                document.getElementById("close-search-field").classList.add("visible")
            } else {
                document.getElementById("close-search-field").classList.remove("visible")
            }
        })

        return {
            queryText,
            searchInFocus,
            searchInputRef,
            t,
            suggestions,
            showSuggestions,
            searchOpened,
            onchangeSearchQuery,
            chooseSearchSuggestion,
            focusSearch,
            blurSearch,
            cleanQuery,
            toSearchPage,
            keyboardKeyUp,
        }
    },
}
</script>
